<template>
    <b-card-code title="الاصدارات العلمية" no-body>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- filter -->
          <b-form-group
            label="فلتر"
            label-cols-sm="1"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="md">
              <b-form-input
                id="filterInput"
                v-model="title"
                type="search"
                placeholder="ابحث عن.."
              />
              <b-input-group-append>
                <b-button @click="resetSearch()"> الغاء الفلتر </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-modal.model-a-w
                >
                  <feather-icon icon="FilePlusIcon" class="mr-50" />
                  <span class="align-middle">اضافة مادة جديدة</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
        <b-form-row style="margin-top: 10px">
        <b-col v-show="js.length > 0" md="3">
          <b-form-group>
            <v-select
              v-model="js_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.js"
              placeholder="اختر العلمية"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="ys.length > 0" md="3">
          <b-form-group>
            <v-select
              v-model="ys_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="begin"
              :options="search.ys"
              placeholder="اختر السنة"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      </b-card-body>
  <div class="demo-vertical-spacing">
        <b-progress
        v-if="precentage !== 0"
          :value="precentage"
          max="100"
        />
        </div>
      <b-table
        ref="table"
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :items="items"
        :fields="fields"
      >
        <template #cell(action)="action">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-warning"
                v-b-modal.model-e-w
                @click="eRM(action.item)"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
              </b-button>
            </b-col>
            <b-col  md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-modal.modal-center
              @click="dRM(action.item)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
            </b-button>
          </b-col>
          </b-dropdown>
        </template>
        
      <template #cell(logo)="logo">
        <b-avatar size="lg" :src="getImage(logo.item.image)" />
      </template>
      </b-table>
  
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            @change="check()"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
  
        <!-- pagination -->
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @change="check()"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>
      <b-modal
        id="model-e-w"
        ok-title="حفظ التعديلات"
        centered
        size="lg"
        title="تعديل اصدار"
        @ok="uM"
        @hidden="rEF"
      >
      <b-form novalidate class="needs-validation" >
        <b-form-row>
          <b-col md="12" style="margin-bottom: 20px;">
            <label for="input-valid1">العنوان بالعربي :</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.title"
              :state="editform.title.length > 0"
              placeholder="العنوان بالعربي "
            />
          </b-col>
          <b-col v-show="js.length > 0" md="6">
          <b-form-group>
            <v-select
              v-model="editform.js_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="js"
              placeholder="اختر المجلة العلمية"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="ys.length > 0" md="6">
          <b-form-group>
            <v-select
              v-model="editform.ys_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="begin"
              :options="ys"
              placeholder="اختر السنة"
            />
          </b-form-group>
        </b-col>
        </b-form-row>
      </b-form>
      </b-modal>
      <b-modal
        id="model-a-w"
        ok-title="حفظ البيانات"
        centered
        size="lg"
        title="اضافة مادة جديدة"
        @ok="aNM"
        @hidden="rF"
      >
        <b-form novalidate class="needs-validation" >
          <b-form-row>
            <b-col md="12" style="margin-bottom: 20px;">
            <label for="input-valid1">العنوان بالعربي :</label>
            <b-form-input
              id="input-valid1"
              v-model="form.title"
              :state="form.title.length > 0"
              placeholder="العنوان بالعربي "
            />
          </b-col>
          <b-col v-show="js.length > 0" md="6">
          <b-form-group>
            <v-select
              v-model="form.js_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="js"
              placeholder="اختر المجلة العلمية"
            />
          </b-form-group>
        </b-col>
        <b-col v-show="ys.length > 0" md="6">
          <b-form-group>
            <v-select
              v-model="form.ys_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="begin"
              :options="ys"
              placeholder="اختر السنة"
            />
          </b-form-group>
        </b-col>
                  </b-form-row>
        </b-form>
      </b-modal>
      <b-modal
      id="modal-center"
      centered
      title="حذف مجلة"
      @ok="dM"
      ok-title="Accept"
    >
      <b-card-text>
        هل تريد حذف هذه الحقل ؟
      </b-card-text>
    </b-modal>
    </b-card-code>
  </template>
  
  <script>
  import vSelect from "vue-select";
  import Cleave from "vue-cleave-component";
  import { filesUrl } from "@/main.js";
  import store from "@/store";
  import 'cleave.js/dist/addons/cleave-phone.us'
  import { togglePasswordVisibility } from "@core/mixins/ui/forms";
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  import {
    BTable,
    BAvatar,
    BModal,
    BDropdown,
    BDropdownItem,
    BFormCheckboxGroup,
    BForm,
    BFormRow,
    BCol,
    VBModal,
    BBadge,
    BFormValidFeedback,
    BFormInvalidFeedback,
    BFormGroup,
    BFormCheckbox,
    BCardText,
    BFormSelect,
    BPagination,BProgress,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,BFormTextarea,
    BButton,
    BCardBody,BFormFile
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  
  export default {
    components: {
      BCardCode,
      BTable,
      BForm,
      vSelect,
      BAvatar,
      BBadge,
      BFormRow,BProgress,BFormTextarea,
      BDropdown,
      Cleave,
      BDropdownItem,
      BFormGroup,
      togglePasswordVisibility,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormCheckboxGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BCardBody,
      BModal,
      VBModal,
      Ripple,
      BCol,
      BFormCheckbox,
      BCardText,
      BFormValidFeedback,
      BFormInvalidFeedback,BFormFile
    },
    data() {
      return {
        precentage:0,
        teacher_id: "",
        perPage: 5,
        pageOptions: [3, 5, 10, 100],
        totalRows: 1,
        users:[],
        role: null,
        canDelete: null,
        canEdit: null,
        currentPage: 0,
        ys_id: "",
        js_id: "",
        type_id: "",
        fromdate:"",
        todate:"",
        carency_id:"",
        carency:[],
        title: "",
        filter: null,
        date: {
            date: true,
            delimiter: '-',
            datePattern: ['Y', 'm', 'd'],
            },
        format:{
          price: {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          },
          number: {
            creditCard: true,
          },
        },
        forwardform:{
          user_id:"",
          instorage_id:"",
          count:"",
          note:"",
          invoise:"",
          date:""
        },
        filterOn: [],
        js: [],
          ys: [],
        search:{
            js_id: [],
            ys_id: [],
        },
        fields: [
          {
            key: "title",
            label: "الاصدار ",
          },
          {
            key: "journal.ar_title",
            label: "المجلة العلمية",
          },
          {
            key: "year.begin",
            label: "السنة",
          },
          { key: "action", label: "الاجراءات" },
        ],
        exportFields:{
            'العنوان': 'title',
              'عدد المواد': 'count',
              'سعر المادة': 'price',
              'رقم الوصل': 'invoise',
              'تاريخ الوصل': 'date',
              'ملاحظة': 'note',
              'عملة الشراء': 'carency.title',
              'نوع المادة': 'type.ar_title',
              'النوع الثانوي': 'subtype.ar_title',
        },
        exportData:[
          {
            title: "title",
            count: "count",
            price: "price",
            invoise: "invoise",
            date: "date",
            note: "note",
            carency: 'carency.title',
            type: 'type.ar_title',
            subtype: 'subtype.ar_title',
          },
        ],
        items: [],
        form: {
            title: "",
            js_id: "",
            ys_id: "",
        },
        editform: {
            title: "",
            js_id: "",
            ys_id: "",
          id: null
        },
        selected: [],
        selected2: [],
        options: {
          time: {
            time: true,
            timePattern: ["h", "m"],
          },
        },
      };
    },
    watch: {
      title: function (val) {
        this.gS();
      },
      "ys_id": function (val) {
        if (val != null && val != "") {
          this.gS();
        }
      },
      "ys_id": function (val) {
        if (val != null && val != "") {
          this.gS();
        }
      },
    },
    mixins: [togglePasswordVisibility],
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
      },
    },
    directives: {
      Ripple,
    },
    mounted() {
      this.ID(), this.gS(), this.gSM(), this.gSM1(), this.gSM2(), this.gSM3();
    },
    methods: {
      async gSM() {
        await this.axios
          .get(
            `journals?take=1000&skip=0`
          )
          .then((res) => {
            this.js = [];
            this.search.js = [];
            this.js = res.data.items;
            this.search.js = res.data.items;
          })
      },
      async gSM1() {
        await this.axios
          .get(
            `years`
          )
          .then((res) => {
            this.ys = [];
            this.search.ys = [];
            this.ys = res.data.items;
            this.search.ys = res.data.items;
          })
      },
      async gSM2() {
        await this.axios
          .get(
            `categories?take=100&skip=0&str=true`
          )
          .then((res) => {
            this.categories = [];
            this.search.categories = [];
            this.categories = res.data.items;
            this.search.categories = res.data.items;
          })
      },async gSM3() {
        await this.axios
          .get(
            `carency`
          )
          .then((res) => {
            this.carency = [];
            this.search.carency = [];
            this.carency = res.data.items;
            this.search.carency = res.data.items;
          })
      },
      async uM() {
        if (isNaN(this.editform.js_id)) {
        this.editform.js_id = this.editform.js_id["id"];
      }
      if (isNaN(this.editform.ys_id)) {
        this.editform.ys_id = this.editform.ys_id["id"];
      }
        let data = new FormData();
        data.append("title", this.editform.title);
        data.append("year_id", this.editform.ys_id);
        data.append("journal_id", this.editform.js_id);
        await this.axios
          .post(`issues/${this.editform.id}`, data,{
            onUploadProgress: progressEvent => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            }
          })
          .then((res) => {
              this.gS();
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.makeToast(
                "success",
                "update Success",
                "Your update is done successfully"
              );
            }
            this.precentage=0
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
          this.rEF()
      },
      async aNM() {
        if (isNaN(this.form.js_id)) {
        this.form.js_id = this.form.js_id["id"];
      }
      if (isNaN(this.form.ys_id)) {
        this.form.ys_id = this.form.ys_id["id"];
      }
        let data = new FormData();
        data.append("title", this.form.title);
        data.append("year_id", this.form.ys_id);
        data.append("journal_id", this.form.js_id);
        await this.axios
          .post(`issues`, data,{
            onUploadProgress: progressEvent => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            }
          })
          .then((res) => {
              this.gS();
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.makeToast(
                "success",
                "update Success",
                "Your model is added successfully"
              );
            }
            this.rF();
            this.precentage=0
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
        this.rF();
      },
     
      rF() {
        this.form.title = ""
        this.form.js_id = ""
        this.form.ys_id = ""
      },
      rEF() {
        this.editform.title = ""
        this.editform.js_id = ""
        this.editform.ys_id = ""
        this.editform.id = "";
      },
      async dM() {
        await this.axios
          .delete(`issues/${this.editform.id}`)
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "Delete Success",
                "Your item is deleted successfully"
              );
            }
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      errorToast() {
        this.makeToast(
          "danger",
          "update failed",
          "some thing want wrong, i`m sorry :("
        );
      },
      eRM(item) {
        this.editform.title = item.title;
        this.editform.js_id = item.journal_id;
        this.editform.ys_id = item.year_id;
        this.editform.id = item.id;
      },
      dRM(item) {
        this.editform.title = item.title;
        this.editform.id = item.id;
      },
      check() {
        setTimeout(() => this.gS(), 1000);
      },
      ID() {
        this.teacher_id = localStorage.getItem("NisourTeacherId");
      },
      async gS() {
        
        if (isNaN(this.js_id)) {
        this.js_id = this.js_id["id"];
      }
      if (isNaN(this.ys_id)) {
        this.ys_id = this.ys_id["id"];
      }
        await this.axios
          .get(
            `issues?take=${this.perPage}&skip=${this.currentPage - 1}&title=${
              this.title
            }&journal_id=${this.js_id}&year_id=${this.ys_id}` 
          )
          .then((res) => {
            this.items = [];
            this.totalRows = res.data.totalCount;
            this.items = res.data.items;
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "danger",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
      },
      makeToast(variant = null, title = null, body = null) {
        this.$bvToast.toast(body, {
          title: title,
          variant,
          solid: false,
        });
      },
      resetSearch() {
        this.ys_id = "",
        this.js_id = "",
        this.gS();
      },
      selectFileForForm(e) {
        this.form.file = "";
        console.log(e.target.files);
        console.log(e.target.files[0]);
        this.form.file = e.target.files[0];
        console.log(this.form.files);
      },
      selectFileForEditForm(e) {
        this.editform.file = "";
        console.log(e.target.files);
        console.log(e.target.files[0]);
        this.editform.file = e.target.files[0];
        console.log(this.editform.file);
      },
      onClicked(path) {
        console.log(path)
        window.open(`${filesUrl}/${path}`, "_blank");
      },
      getImage(path) {
      return `${filesUrl}/${path}`;
    },
    },
  };
  </script>
  